<template>
    <div class="w-full">
        <p
            id="helper-text-explanation"
            class="text-md text-gray-500 dark:text-gray-100 mb-2"
        >
            Let us know which 3 sessions have your preference.
        </p>
        <div class="flex space-x-4 justify-between">
            <div class="w-full">
                <label for="option_one">Session 1</label>
                <select
                    :class="select_class"
                    id="option_one"
                    v-model="option_one"
                    required
                >
                    <option value="14:45 - 15:25 1A - Control Amsterdam">14:45 - 15:25 1A - Control Amsterdam</option>
                    <option value="14:45 - 15:25 1B - The phenomenon Vertical">14:45 - 15:25 1B - The phenomenon Vertical</option>
                    <option value="14:45 - 15:25 1C - Behavioral influence">14:45 - 15:25 1C - Behavioral influence</option>
                    <option value="14:45 - 15:25 1D - Photography / Creation">14:45 - 15:25 1D - Photography / Creation</option>
                    <option value="14:45 - 15:25 1E - The State of DOOH 2025">14:45 - 15:25 1E - The State of DOOH 2025</option>
                </select>
            </div>
            <div class="w-full">
                <label for="option_two">Session 2</label>
                <select
                    :class="select_class"
                    id="option_two"
                    v-model="option_two"
                    required
                >
                    <option value="15:35 - 16:15 2A - Control Amsterdam">15:35 - 16:15 2A - Control Amsterdam</option>
                    <option value="15:35 - 16:15 2B - The phenomenon Vertical">15:35 - 16:15 2B - The phenomenon Vertical</option>
                    <option value="15:35 - 16:15 2C - Behavioral influence">15:35 - 16:15 2C - Behavioral influence</option>
                    <option value="15:35 - 16:15 2D - Photography / Creation">15:35 - 16:15 2D - Photography / Creation</option>
                    <option value="15:35 - 16:15 2E - The State of DOOH 2025">15:35 - 16:15 2E - The State of DOOH 2025</option>
                </select>
            </div>
            <div class="w-full">
                <label for="option_three">Session 3</label>
                <select
                    :class="select_class"
                    id="option_three"
                    v-model="option_three"
                    required
                >
                    <option value="16:25 - 17:05 3A - Control Amsterdam">16:25 - 17:05 3A - Control Amsterdam</option>
                    <option value="16:25 - 17:05 3B - The phenomenon Vertical">16:25 - 17:05 3B - The phenomenon Vertical</option>
                    <option value="16:25 - 17:05 3C - Behavioral influence">16:25 - 17:05 3C - Behavioral influence</option>
                    <option value="16:25 - 17:05 3D - Photography / Creation">16:25 - 17:05 3D - Photography / Creation</option>
                    <option value="16:25 - 17:05 3E - The State of DOOH 2025">16:25 - 17:05 3E - The State of DOOH 2025</option>
                </select>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, watch } from "vue";

// CSS class for styling
const select_class =
    "block w-full p-2.5 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500";

// Model to bind to the parent v-model
const model = defineModel();

// Local state for each dropdown
const option_one = ref("");
const option_two = ref("");
const option_three = ref("");

// Watch for changes to the dropdown values and update the model
watch([option_one, option_two, option_three], () => {
    model.value = `| Sessie 1: ${option_one.value}, Sessie 2: ${option_two.value}, Sessie 3: ${option_three.value} |`;
});
</script>

<style lang="scss" scoped></style>
